import React, { useEffect, useState } from 'react';
import '../css/Slider.css';
import promo_Img from '../assets/other_img/cute-painter.png';
import { Link } from 'react-router-dom';

const Slider = () => {
  const [promoIndex, setPromoIndex] = useState(0);
  const promoTexts = ["Successful", "Colorful", "Bright"];
  const [isFading, setIsFading] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsFading(true);
      setTimeout(() => {
        setPromoIndex((prevIndex) => (prevIndex + 1) % promoTexts.length);
        setIsFading(false);
      }, 500); // Match this duration with CSS transition
    }, 2000);

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  return (
    <div className='slider_container'>
      <div className='slider'>
        <div className='left'>
          <div className='content'>
            <div className='heading'>
              <h1 >
                Empowering Kids to Inspire a  <span className={`promo-text ${isFading ? 'fade-out' : 'fade-in'}`}> {promoTexts[promoIndex]}</span>
              </h1>
            </div>
            <div className='sub-Content'>
              <ul>
                <li>Become an Artist</li>
                <li>Nurture Your Passion</li>
                <li>Express Your Vision</li>
              </ul>
            </div>
            <div className='btn-container'>
             <Link to={'https://docs.google.com/forms/d/1K_obPpsF3UIXo8Ew0l08NSaHZa63Wd6IDj4tIkgY74U/edit'} target='_black'>
              <div className='btn'>Admission Now</div>
           </Link>
            </div> 
          </div>
        </div>
        <div className='right'>
          <div className='img'>
            <img src={promo_Img} alt='promo' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
